import React, { useEffect, useState } from "react";
import Header2 from "../Comman/Header2";
import Footer2 from "../Comman/Footer2";
import DashboardSidebar from "./DashboardSidebar";
import Statstics from "./Statstics";
import { Modal } from 'react-bootstrap';

export default function Dashboard() {
  const [showMenu, setShowMenu] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    setShow(true);
  }, []);
  return (
    <>
      <Header2 showMenu={showMenu} setShowMenu={setShowMenu} />

      <DashboardSidebar showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className="container dash-res ">
        <div className="row gy-5 gy-md-6 pt-120">
          <div className="col-12">
            <div className="earn_crypto__title text-sm-center mb-7 mb-md-11 mt-11 wow fadeInUp">
              <h2 className="text-center mb-3">
                {" "}
                <span className="p1-color">Dashboard</span>
              </h2>
              <Statstics />
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
      <Modal show={show} onHide={handleClose} centered className="w-100 ">
        <div class="modal-content border-0 rounded w-100 ">
          {/* <img src="./assets/img/happy-new-years.jpg" alt="" srcset="" /> */}
          <img src="./assets/images/bonanza_launch_offer.jpg" alt="" srcset="" />
        </div>
      </Modal>
      {/* <Staking /> */}
    </>
  );
}
