import "./App.css";
import Dashboard from "./Components/Dashboard/Dashboard";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./Components/Home/Index";
import Invest from "./Components/Dashboard/Invest/Invest";
import DashboardMain from "./Components/Dashboard/DashboardMain";
import DailyReward from "./Components/Dashboard/DailyReward";
import DirectReward from "./Components/Dashboard/DirectReward";
import LevelReward from "./Components/Dashboard/LevelReward";
import SalaryReward from "./Components/Dashboard/SalaryReward";
import RewardIncome from "./Components/Dashboard/RewardIncome";
import Team from "./Components/Dashboard/Team";
import WitndwaralMain from "./Components/Dashboard/WitndwaralMain";
import Login from "./Components/login/Login";
import Register from "./Components/Register/Register";
import Forgot from "./Components/forgot/Forgot";
import Reset from "./Components/reset/Reset";

function App() {
  return (
    <>
      {/* <Dashboard/> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/reset-password" element={<Reset />} />

          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/:link_ref_address" element={<Dashboard />} />
          <Route path="/staking" element={<DashboardMain />} />
          <Route path="/reward" element={<Invest />} />
          <Route path="/daily-reward" element={<DailyReward />} />
          <Route path="/direct-reward" element={<DirectReward />} />
          <Route path="/level-reward" element={<LevelReward />} />
          <Route path="/salary-reward" element={<SalaryReward />} />
          <Route path="/reward-income" element={<RewardIncome />} />
          <Route path="/team" element={<Team />} />
          <Route path="/withdrawal" element={<WitndwaralMain />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
