import React, { useContext, useEffect, useState } from "react";
import { walletConnectContext } from "../../Context/WalletConnectContext";
import axios from 'axios';
import Pagination from "../Comman/Pagination";

export default function WithdrawalList() {
  const { user_id } = useContext(walletConnectContext)
  const [data, setdata] = useState([]);
  const [datap, setdatap] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    if (!user_id) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "getbyid",
        // u_id: "0x501c21a99aaad6a36bd811884c2a1ca2cacdf21f",
        u_id: user_id,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  const getpData = async () => {
    if (!user_id) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "getpbyid",
        u_id: user_id,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdatap(res.data.data);
      });
  };


  useEffect(() => {
    getData();

  }, [user_id, pages, pageSize, currentPage]);
  useEffect(() => {
    getpData();
  }, [user_id]);



  return (

    <div className="row ">
      <div className="col-12">
        {datap !== null ?
          <div className="earn_crypto__title text-sm-center mb-7 mb-md-11 mt-11 wow fadeInUp">
            <h2 className="text-center mb-5">
              <span className="p1-color pb-3">Pending Withdrawal List</span>
            </h2>
            <div className="pools_table__totalitem overflow-auto">
              <table id="mytable" className="table align-middle mb-0">
                <thead className="">
                  <tr className="header-row">
                    <th>#</th>
                    <th>Address</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Rate</th>
                    <th>Fee</th>
                    <th>Received Amount</th>
                    {/* <th>Hash</th> */}
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center">
                    {isLoading ? "Data is loading" : ""}
                  </tr>

                  {datap.map((ele, i) => {
                    return (
                      <tr>
                        <td>
                          {i + 1}
                        </td>
                        <td > {ele.address}
                        </td>
                        <td > {ele.datetime}
                        </td>
                        <td >
                          $ {ele.amount}
                        </td>
                        <td >
                          $ {ele.rate}
                        </td>
                        <td >
                          {ele.fee} USDT (10%)
                        </td>
                        <td >
                          {ele.t_rcv} USDT
                        </td>
                        <td className='text-warning'> Pending
                        </td>


                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div> : ''}
        <div className="earn_crypto__title text-sm-center mb-7 mb-md-11 mt-11 wow fadeInUp">
          <h2 className="text-center mb-5">
            <span className="p1-color pb-3">Withdrawal List</span>
          </h2>
          <div className="pools_table__totalitem overflow-auto">
            <table id="mytable" className="table align-middle mb-0">
              <thead className="">
                <tr className="header-row">
                  <th>#</th>
                  <th>Address</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Rate</th>
                  <th>Fee</th>
                  <th>Received Amount</th>
                  {/* <th>Hash</th> */}
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  {isLoading ? "Data is loading" : ""}
                </tr>
                {!isLoading ? (
                  !data.length ? (
                    <tr className="text-center">
                      <td className="text-center" colSpan={7}>
                        <span className="w-100">No data found</span>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {data.map((ele, i) => {
                  return (
                    <tr>
                      <td>
                        {i + 1}
                      </td>
                      <td > {ele.address}
                      </td>
                      <td > {ele.datetime}
                      </td>
                      <td >
                        $ {ele.amount}
                      </td>
                      <td >
                        $ {ele.rate}
                      </td>
                      <td >
                        {ele.fee} USDT (10%)
                      </td>
                      <td >
                        {ele.t_rcv} USDT
                      </td>
                      <td className='text-success'> Success
                      </td>

                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pages={pages}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
