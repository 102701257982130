import React, { useContext, useEffect, useState } from "react";
import { walletConnectContext } from "../../Context/WalletConnectContext";
import { useParams } from "react-router-dom";
import axios from 'axios';
import QRCode from "react-qr-code";
export default function Stake() {
  const { link_ref_address } = useParams();

  const { dbuser, user_id, toastError, tokenrate, getuserDB, toastSuccess, copyaddress, toastInfo } = useContext(walletConnectContext);
  const [amount, setamount] = useState(0);
  const [token, settoken] = useState(0);
  const [trdone, settrdone] = useState(false);
  const [hash, sethash] = useState("");
  const [referral, setreferral] = useState("");
  const [selectedOption, setSelectedOption] = useState("pkg1");

  const onDeposit = async () => {
    sethash("");
    if (!user_id) {
      toastError("Login first");
      settrdone(false);
      return;
    }
    if (amount % 50 !== 0) {
      toastError("Amount Must be divisible by 50");
      settrdone(false);
      return;
    }

    // if (!tandc) {
    //     toastError("Accept terms and conditions");
    //     settrdone(false);
    //     return;
    // }
    const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');

    // const receipt = await stk.wait();
    // sethash(receipt.transactionHash);
    sethash(randomHash);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "transaction",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        u_id: user_id,
        ref_id: referral,
        hash: randomHash,
        token: token,
        amount: amount,
        rate: tokenrate,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          toastError(res.data.error);
          return "";
        }
        getuserDB();
        toastSuccess("Transaction done");
        settrdone(false);
      });


  };
  useEffect(() => {
    if (dbuser !== null) {
      if (dbuser.ref_id !== null) {
        setreferral(dbuser.ref_id);
      }
    } else {
      setreferral(link_ref_address);
    }
  }, [link_ref_address, dbuser, user_id]);

  return (
    <>
      {dbuser === null ?
        <div className="buy_crypto__formarea-group mb-5 mb-md-6">
          <label className="mb-2">Referral </label>
          <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
            <input type="text" placeholder="Enter Referral Address" value={referral} onChange={(e) => { setreferral(e.target.value); }} />
          </div>
        </div> : ''}
      <div className="d-flex justify-contetn-center">
        <div>
          <input type="radio" id="pkg1" name="pkgs" value="pkg1" defaultChecked checked={selectedOption === "pkg1"}
            onChange={(e) => setSelectedOption(e.target.value)}
          />
          <label htmlFor="pkg1">Package 1</label><br />
        </div>
        <div>
          <input type="radio" id="pkg2" name="pkgs" value="pkg2" checked={selectedOption === "pkg2"}
            onChange={(e) => setSelectedOption(e.target.value)}
          />
          <label htmlFor="pkg2">Package 2</label><br />
        </div>
      </div>
      <div className="buy_crypto__formarea-group mb-5 mb-md-6">
        <label className="mb-2">Amount</label>
        <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
          <input type="text" placeholder="0" onChange={(e) => setamount(Number(e.target.value))} />
        </div>
      </div>
      <div className="d-flex align-items-center gap-1  mb-md-6 justify-content-between flex-wrap">
        <span>Minimum:</span>
        <span>{selectedOption === 'pkg1' ? 50 : 1001} USDT</span>
      </div>
      <div className="d-flex align-items-center gap-1  mb-md-6 justify-content-between flex-wrap">
        <span>Maximum:</span>
        <span>{selectedOption === 'pkg1' ? 1000 : 10000} USDT</span>
      </div>
      <div className="d-flex align-items-center gap-1 mb-5 mb-md-6 justify-content-end flex-wrap">
        <span>Token:</span>
        <span>{amount} USDT</span>
      </div>
      <div className="text-center p-1">
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "150px", width: "100%" }}
          value={dbuser ? dbuser.depositAddress?dbuser.depositAddress:"no data" : 'no data'}
          viewBox={`0 0 256 256`}
        />
        <p style={{ fontSize: "11px" }}>*It will take 2-3 minutes after transaction to reflect in your account</p>
        <p className="text-break" onClick={() => copyaddress(dbuser ? dbuser.depositAddress : '')}>{dbuser ? dbuser.depositAddress : ''} </p>
        <p>{dbuser ? dbuser.depositBalance : 0} USDT</p>
      </div>


      {trdone ? (
        <div className='text-center'>
          <img
            className="border-0"
            src="../assets/img/loader.gif"
            alt="loading"
            height={100}
            width={100}
          />
        </div>
      ) : (
        <a type="button" className="cmn-btn py-3 px-5 px-md-6 d-block " onClick={() => onDeposit()}>
          Start saving
        </a>
      )}

    </>
  );
}
