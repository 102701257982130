import React from "react";

export default function Footer2() {
  return (
    <>
      <footer className="footer pt-120 bg-white">
        <div className="container-fluid ">
          <div className="row align-items-center justify-content-center py-3 py-sm-4 py-lg-6 bg2-color">
            <div className="col-sm-10 col-xxl-8 order-2 order-sm-1">
              <div className="footer__copyright text-center d-flex align-items-center justify-content-center flex-wrap flex-md-nowrap">
                <div className="coyp-rightarea">
                  <span className="p4-color roboto text-center">
                    Copyright 2025 - &nbsp;
                    <a href="#" className="p4-color">
                      Levish&nbsp;
                    </a>
                    All Rights Reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
