import React, { useContext, useEffect, useState } from "react";
import { walletConnectContext } from "../../Context/WalletConnectContext";
import axios from 'axios';

export default function Witndwaral() {
  const {
    user_id,
    toastSuccess, dbbalance,
    tokenrate,
    toastError,
    getuserDB, dbuser,
  } = useContext(walletConnectContext)

  const [address, setaddress] = useState(null);
  const [amount, setamount] = useState(0);
  const [rcv_amount, setrcv_amount] = useState(0);
  const [fees, setfees] = useState(0);
  const [total_tokens, settotal_tokens] = useState(0);
  const [trdone, settrdone] = useState(false);


  const withdraw = async () => {
    if (!user_id) {
      // connectMetamask();
      toastError("Login first");
      settrdone(false);
      return;
    }
    if (!address) {
      // connectMetamask();
      toastError("Enter address");
      settrdone(false);
      return;
    }
    if (!amount || amount < 0) {
      toastError("Enter amount");
      settrdone(false);
      return;
    }
    if (amount < 20) {
      toastError("Min $20 required");
      settrdone(false);
      return;
    }
    if (dbuser.restake) {
      toastError("Restake First");
      settrdone(false);
      return;
    }
    if (Number(amount) > dbbalance) {
      toastError("Insufficient Balance");
      settrdone(false);
      return;
    }
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        u_id: user_id,
        amount: amount,
        token: total_tokens,
        fee: fees,//admin
        t_rcv: rcv_amount,
        rate: tokenrate,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          toastError(res.data.error);
          return "";
        }
        toastSuccess(res.data.data);
      });
    getuserDB();
    settrdone(false);
  }
  useEffect(() => {
    var tmt = amount / tokenrate;
    var tmtfee = (tmt * 10) / 100;
    settotal_tokens(tmt);
    setfees(tmtfee);
    setrcv_amount(tmt - tmtfee);
  }, [amount, tokenrate]);



  return (
    <>
      <section className="buy_crypt  pb-120">
        <div className="container pt-17 pt-sm-20 pt-lg-0">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-8">
              <div
                className="buy_crypto__formarea p-6 p-px-8 rounded-20 bg7-color wow fadeInUp"
                style={{ border: "2px solid #ff7e25" }}
              >
                {/* <h2 className="mb-3">Buy Crypto</h2>
                <span className="mb-8 mb-md-10">Buy In Seconds</span> */}
                <div className="buy_crypto__formarea-group mb-5 mb-md-6">
                  <div className="d-flex justify-content-between">
                    <label className="mb-2">Amount</label>
                    <label className="mb-2">Balance: {dbbalance} USDT</label>
                  </div>
                  <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                    <input type="text" placeholder="0" value={amount}
                      onChange={(e) =>
                        setamount(e.target.value)
                      } />
                  </div>
                  <div className="d-flex justify-content-between">
                    <label className="mb-2">Address (BEP20)</label>
                  </div>
                  <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                    <input type="text" placeholder="Enter Address" value={address}
                      onChange={(e) =>
                        setaddress(e.target.value)
                      } />
                  </div>
                  <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Rate</span> <span> $ {tokenrate}</span></div>
                  <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Total Token</span> <span>{total_tokens}{" "} USDT</span></div>
                  <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Fees</span> <span>{fees}{" "} USDT (10%)</span></div>
                  <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Received Amount</span> <span>{rcv_amount}{" "}
                    USDT</span></div>
                </div>
                <a type="button" className="cmn-btn py-3 px-5 px-md-6 d-block" onClick={() => withdraw()}>
                  Withdrawal
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  );
}
