import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Header() {
  return (
    <>
      <header className="header-section header-menu w-100 pt-1 pt-lg-0 pb-3 pb-lg-0">
        <div className="navbar_mainhead header-fixed w-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <nav className="navbar navbar-expand-lg position-relative py-md-3 py-lg-3 workready">
                  <Link
                    to="/"
                    className="navbar-brand d-flex align-items-center gap-2"
                  >
                    <img
                      src="../assets/img/levish_logo.png"
                      width="150"
                      className="logo"
                      alt="logo"
                    />
                  </Link>
                  <div
                    className="collapse navbar-collapse justify-content-between"
                    id="navbar-content"
                  >
                    <ul className="navbar-nav d-flex align-items-lg-center gap-5 gap-lg-1 gap-xl-4 gap-xxl-7 py-2 py-lg-0 ms-2 ms-xl-10 ms-xxl-20 ps-0 ps-xxl-10 align-self-center">
                      <li className="dropdown">
                        <Link to="/" className="fs-ten">
                          Home
                        </Link>
                      </li>
                      <li className="dropdown">
                        <HashLink smooth to="/#About" className="fs-ten">
                          About Us
                        </HashLink>
                      </li>
                      <li className="dropdown">
                        <HashLink smooth to="/#Faq" className="fs-ten">
                          FAQ
                        </HashLink>
                      </li>
                      {/* <li className="dropdown">
                                                <a href="/dashboard" className="fs-ten">Dashboard</a>
                                            </li> */}
                    </ul>
                  </div>
                  <div className="right-area custom-pos position-relative d-flex gap-0 gap-lg-2 align-items-center">
                    <div className="header-section__modalstyle">
                      <Link type="button" to="/login" className="cmn-btn px-3 px-sm-5 px-md-6 py-2 py-sm-3 d-flex align-items-center gap-1">
                        Login
                      </Link>
                    </div>
                  </div>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    aria-label="Navbar Toggler"
                    data-bs-target="#navbar-content"
                    aria-expanded="true"
                    id="nav-icon3"
                  >
                    <span />
                    <span />
                    <span />
                    <span />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="modal_areastyle">
        {/* Modal */}
        <div
          className="modal"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content px-2 px-md-6 pt-md-3 pb-2 pb-md-6">
              <div className="modal-header border-0 d-flex justify-content-between">
                <h3 className="modal-title p1-color" id="exampleModalLabel">
                  Connect Your Wallet
                </h3>
                <button
                  type="button"
                  className="btn-close p7-color text-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-xbox-x p6-color fs-three fw-normal" />
                </button>
              </div>
              <div className="modal-body">
                <a
                  href="javascript:void(0)"
                  className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between mb-5 mb-md-6"
                >
                  <span className="fw_500">Conntect with Metamask</span>
                  <img src="assets/images/icon/dog.png" alt="Icon" />
                </a>
                <a
                  href="javascript:void(0)"
                  className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between"
                >
                  <span className="fw_500">Conntect with Metamask</span>
                  <img src="assets/images/icon/wallet-connect.png" alt="Icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
