import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from "axios";
import { walletConnectContext } from '../../Context/WalletConnectContext';

export default function RegisterMain() {
    const {
        toastError,
        toastSuccess,
    } = useContext(walletConnectContext);

    const [referral, setreferral] = useState("");
    const [password, setpassword] = useState("");
    const [email, setemail] = useState("");
    const [city, setcity] = useState("");
    const [mnumber, setmnumber] = useState("");
    const [tandc, settandc] = useState(false);

    const [otp, setotp] = useState("");
    const [otpsent, setotpsent] = useState(false);
    const goto = useNavigate()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const link_ref_address = searchParams.get('referral');

    const register = async () => {
        if (!tandc) {
            toastError("Accept terms and conditions");
            return;
        }
        if (!email) {
            toastError("Enter Email");
            return;
        }
        const isValidEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
        if (!isValidEmail(email)) {
            toastError("Enter valid Email");
            return;
        }
        if (!password) {
            toastError("Enter Password");
            return;
        }
        if (!referral) {
            toastError("Enter Referral");
            return;
        }

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "email",
                submethod: "insert",
                email: email,
                referral_id: referral,
                password: password,
                city: city,
                mnumber: mnumber,
                key: process.env.REACT_APP_KEY,
            })
            .then((res) => {
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    toastError(res.data.error);
                    return "";
                }
                toastSuccess('OTP sent')
                setotpsent(true)
            });
    };

    const otp_verify = async () => {
        if (!otp) {
            toastError("Enter OTP");
            return;
        }
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "email",
                submethod: "verify",
                email: email,
                otp: otp,
                key: process.env.REACT_APP_KEY,
            })
            .then((res) => {
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    toastError(res.data.error);
                    return "";
                }
                toastSuccess(res.data.data)
                setotpsent(false)
                goto("/login")
            });
    };

    useEffect(() => {
        setreferral(link_ref_address);
    }, [link_ref_address]);

    return (
        <>
            <section className="buy_crypt pt-120 pb-120">
                <div className="container pt-17 pt-sm-20 pt-lg-0">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-8">
                            {otpsent ?
                                <div
                                    className="buy_crypto__formarea p-6 p-px-8 rounded-20 bg7-color wow fadeInUp"
                                    style={{ border: "2px solid #ffb91b" }}
                                >
                                    <h2 className="mb-3">Verify OTP</h2>

                                    <div className="buy_crypto__formarea-group mb-5 mb-md-6">
                                        <div className="row">
                                            <div className="col-xl-12 col-md-12 mt-2">
                                                <label className="mb-2">Email</label>
                                                <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                                                    <input
                                                        type="text"
                                                        
                                                        className="text-black"
                                                        value={email}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-md-12 mt-2">
                                                <label className="mb-2">OTP</label>
                                                <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                                                    <input
                                                        type="text"
                                                        
                                                        className="text-black"
                                                        value={otp}
                                                        onChange={(e) =>
                                                            setotp(e.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="cmn-btn w-25 py-3 px-5 px-md-6 d-block" onClick={() => otp_verify()}>
                                        Verify OTP
                                    </button>
                                </div> :
                                <div
                                    className="buy_crypto__formarea p-6 p-px-8 rounded-20 bg7-color wow fadeInUp"
                                    style={{ border: "2px solid #ffb91b" }}
                                >
                                    <h2 className="mb-3">Register</h2>
                                    <h6 className="mb-3 d-flex">Already have an account &nbsp;<Link to="/login" className='text-primary'>Login</Link></h6>
                                    <div className="buy_crypto__formarea-group mb-5 mb-md-6">
                                        <div className="row">
                                            <div className="col-xl-12 col-md-12 mt-2">
                                                <label className="mb-2">Email</label>
                                                <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                                                    <input
                                                        type="text"
                                                        
                                                        className="text-black"
                                                        value={email}
                                                        onChange={(e) =>
                                                            setemail(e.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-md-12 mt-2">
                                                <label className="mb-2">Password</label>
                                                <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                                                    <input
                                                        type="text"
                                                        
                                                        className="text-black"
                                                        value={password}
                                                        onChange={(e) =>
                                                            setpassword(e.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-md-12 mt-2">
                                                <label className="mb-2">City</label>
                                                <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                                                    <input
                                                        type="text"
                                                        
                                                        className="text-black"
                                                        value={city}
                                                        onChange={(e) =>
                                                            setcity(e.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-md-12 mt-2">
                                                <label className="mb-2">Mobile Number</label>
                                                <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                                                    <input
                                                        type="text"
                                                        
                                                        className="text-black"
                                                        value={mnumber}
                                                        onChange={(e) =>
                                                            setmnumber(e.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-md-12 mt-2">
                                                <label className="mb-2">Referral ID</label>
                                                <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                                                    <input
                                                        type="text"
                                                        
                                                        className="text-black"
                                                        value={referral}
                                                        onChange={(e) =>
                                                            setreferral(e.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-md-12 mt-2">
                                                <div class="form-check ">
                                                    <input className="form-check-input form-control" type="checkbox" id="flexCheckChecked" onChange={(event) => settandc(event.target.checked)} />
                                                    <label className="form-check-label" for="flexCheckChecked">
                                                        <span to="#">&nbsp;I agree to Terms and conditions.</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="cmn-btn w-25 py-3 px-5 px-md-6 d-block" onClick={() => register()}>
                                        Register
                                    </button>
                                </div>}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
