import React from 'react'

export default function Footer() {
    return (
        <>
            <footer className="footer pt-10 bg1-color">
                <video muted
                    autoPlay
                    loop id="myVideo" className='footer-video'><source src="./assets/img/levish_footer.mp4" type="video/mp4" /></video>
                <div className="container">
                    <div className="row gy-4 pb-10 justify-content-center">
                        <div className="col-md-12 col-lg-12 col-xxl-12 text-center">
                            <div className="footer__decs wow fadeInUp">
                                <a href="/">
                                    <img src="assets/img/levish-logo-white.png" width="200" className="logo" alt="logo" />

                                </a>
                                <p className="mt-5 mt-md-6 mb-8 mb-md-10 wow fadeInUp text-white">Join the Levish Global Trade Community</p>
                                <div className="contact_info__card-social d-flex align-items-center justify-content-center gap-2 gap-md-3 wow fadeInUp">
                                    <a href="">
                                        <i className="ti ti-brand-facebook-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="">
                                        <i className="ti ti-brand-linkedin p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="">
                                        <i className="ti ti-brand-instagram p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="">
                                        <i className="ti ti-brand-twitter-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2 ms-xxl-20">
                            <div className="footer__discover">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Company</h4>
                                <div className="footer__discover-nav">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten " />
                                            <a href="">About Coinx Wallet</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten " />
                                            <a href="">Join Us</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten " />
                                            <a href="">Blog</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten " />
                                            <a href="">Academy</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Security</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten " />
                                            <a href="">Contact Validator</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten " />
                                            <a href="">Protection fund</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten " />
                                            <a href="">Security</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Assets</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten " />
                                            <a href="">BNB Chain</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten " />
                                            <a href="">Ethereum</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten " />
                                            <a href="">ERC20</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="container-fluid ">
                    <div className="row align-items-center justify-content-center py-3 py-sm-4 py-lg-6 bg2-">
                        <div className="col-sm-10 col-xxl-8 order-2 order-sm-1">
                            <div className="footer__copyright text-center d-flex align-items-center justify-content-center flex-wrap flex-md-nowrap">
                                <div className="coyp-rightarea">
                                    <span className="p4-color roboto text-center text-white">Copyright 2025 -&nbsp;
                                        <a href="#" className="text-white"> Levish</a>
                                        &nbsp;All Rights Reserved.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
