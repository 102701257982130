import React, { useContext, useEffect, useState } from "react";
import Footer2 from "../Comman/Footer2";
import Header2 from "../Comman/Header2";
import DashboardSidebar from "./DashboardSidebar";
import { walletConnectContext } from "../../Context/WalletConnectContext";
import axios from 'axios';
import Pagination from "../Comman/Pagination";

export default function LevelReward() {
  const [showMenu, setShowMenu] = useState(true);
  const { user_id, copyaddress, formatAddress } = useContext(walletConnectContext)
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    if (!user_id) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "roiofroi",
        submethod: "getbyid",
        // u_id: "0x501c21a99aaad6a36bd811884c2a1ca2cacdf21f",
        u_id: user_id,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [user_id, pages, pageSize, currentPage]);



  return (
    <div>
      <Header2 showMenu={showMenu} setShowMenu={setShowMenu} />

      <DashboardSidebar showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className="container dash-res">
        <div className="row gy-5 gy-md-6 pt-120">
          <div className="col-12">
            <div className="earn_crypto__title text-sm-center mb-7 mb-md-11 mt-11 wow fadeInUp">
              <h2 className="text-center mb-5">
                <span className="p1-color pb-3">Level Reward</span>
              </h2>
              <div className="pools_table__totalitem overflow-auto">
                <table id="mytable" className="table align-middle mb-0">
                  <thead className="">
                    <tr className="header-row">
                      <th>#</th>
                      <th>From</th>
                      <th>Stake Amount</th>
                      <th>Level</th>
                      <th>Stake Reward</th>
                      <th>Reward</th>
                      <th>Reward (%)</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      {isLoading ? "Data is loading" : ""}
                    </tr>
                    {!isLoading ? (
                      !data.length ? (
                        <tr className="text-center">
                          <td className="text-center" colSpan={5}>
                            <span className="w-100">No data found</span>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {data.map((ele, i) => {
                      return (
                        <tr>
                          <td >
                            {ele.id}
                          </td>
                          <td >
                            <span onClick={() => copyaddress(ele.from)}>
                              {formatAddress(ele.from)}{" "}
                              <i className="fa fa-copy"></i>
                            </span>
                          </td>
                          <td >
                            $ {ele.staking}
                          </td>
                          <td >
                            {ele.level}
                          </td>
                          <td >
                            $ {ele.s_inc}
                          </td>
                          <td >
                            $ {ele.income}
                          </td>
                          <td >
                            {ele.income_per} %
                          </td>
                          <td >
                            {ele.datetime}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  pages={pages}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </div>
  );
}
