import React, { createContext, useEffect, useState } from 'react'
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import copy from "copy-to-clipboard";
import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_BACKEND_LINK_ONLY);
// const socket = io('http://192.168.0.110:5700');
export const walletConnectContext = createContext();

export default function WalletConnectContext({ children }) {
  const user_id = localStorage.getItem("conuid")

  // address = "0x76f8b2c98613022f5a7773ee5ed6d0009925172f";
  // const { error } = useWeb3ModalError()

  const [balance, setBalance] = useState(0);
  const [settings, setsettings] = useState([]);
  const [pkgs, setpkgs] = useState([]);
  const [esigner, setesigner] = useState(null);
  const [tokenrate, settokenrate] = useState(1);
  const [dbbalance, setdbbalance] = useState(0);
  const [dbuser, setdbuser] = useState(null);
  const [stakcontract, setstakcontract] = useState(null);
  const [reloadData, setreloadData] = useState(false);
  const [account, setaccount] = useState('');


  const formatAddress = (address) => {
    if (address) {
      return address.substr(0, 6) + "..." + address.substr(-6, 6);
    }
  };
  function reload() {
    setreloadData(!reloadData)
  }
  const connectInjected = async () => {
    try {
      // await open();
    } catch (ex) {
      console.log(ex);
    }
  };
  const toastError = (data) => {
    toast.error(data);
  };
  const toastSuccess = (data) => {
    toast.success(data);
  };
  const toastInfo = (data) => {
    toast(data, {
      // Custom Icon
      icon: "❕",
      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  };

  const copyaddress = (address) => {
    copy(address);
    toastSuccess("Copied");
  };

  const getSetting = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "setting",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setsettings(res.data.data);
      });
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "pkg",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setpkgs(res.data.data);
      });

  };

  async function disconnectNow() {
    try {
      // deactivate();
      // disconnect()
      localStorage.setItem("isconnected", false);
      localStorage.removeItem("conuid")
      setdbuser(null)
      setBalance(0);
    } catch (ex) {
      console.log(ex);
    }
  }

  const connectMetamask = async () => {
    try {
      // open();

      localStorage.setItem("isconnected", true);
    } catch (error) {
      alert(error);
    }
  };

  const getudata = async (address) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
      method: "user",
      submethod: "getbyid",
      key: process.env.REACT_APP_KEY,
      u_id: address ? address.toLowerCase() : address,
    });
  };
  function formatDateTime(timestamp) {
    if (timestamp === 0) {
      return '-'
    }
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds

    // Get the date components
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    // Get the time components
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Construct the formatted date-time string
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  }
  const getuserDB = async () => {
    var data = await getudata(user_id);
    if (data.data.error) {
      setdbuser(null);
      return "";
    }
    var dap = data.data.data;
    var fbln =
      dap.level_inc +
      dap.roiofroi_inc +
      dap.staking_inc +
      dap.salary_inc +
      dap.reward_inc -
      (dap.t_withdrawal + dap.pen_withdrawal);
    setdbbalance(Number(fbln).toFixed(2));

    // console.log("res", data.data.data);
    setdbuser(data.data.data);
  };

  useEffect(() => {
    getuserDB(user_id);
  }, [user_id]);
  useEffect(() => {
    // if (localStorage.getItem("isconnected") === "true") {
    //   connectMetamask();
    // }
    getSetting();
  }, []);
  const getsmcs = async () => {
    // var tknrate = await maincontractwrite.tokenrate();
    // settokenrate(Number(ethers.utils.formatUnits(tknrate, 18)).toFixed(2));
  };
  // useEffect(() => {
  //   getsmcs();
  //   // }, [account, address,library, settings]);
  // }, [isConnected, account, settings, esigner, reloadData]);
  // useEffect(() => {
  //   getSigner();
  // }, [isConnected, account, walletProvider]);
  
  useEffect(() => {
    // Listen for new transactions
    socket.connect();

    socket.on("newTransaction", (data) => {
      // console.log("New transaction received:", data);
      if (data.user_id === user_id) {
        if (data.type === 0) {
          toastInfo(data.msg)
        } else if (data.type === 1) {
          toastSuccess(data.msg);
          getuserDB();
        }
      }
    });

    return () => {
      socket.off("newTransaction");
    };
  }, []);

  return (
    <>
      <Toaster
        position="top-left"
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#ffa518",
            color: "#fff",
          },
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />

      <walletConnectContext.Provider value={
        {
         dbbalance,
          settings,
          
          balance,
          user_id,
          dbuser,
          getuserDB,
          esigner,
          tokenrate,
          account: account ? account.toLowerCase() : account,
          setaccount,
          formatAddress,
          toastError,
          toastSuccess,
          getudata,
          toastInfo,
          copyaddress,
          formatDateTime,
          reload,
          disconnectNow
        }}>
        {children}
      </walletConnectContext.Provider>
    </>
  )
}
